@import url('https://fonts.googleapis.com/css2?family=Six+Caps&family=Work+Sans:wght@400;700&family=Dancing+Script:wght@400;700&display=swap');

*{
    padding: 0;
    margin: 0;
    font-size: 16px;
    box-sizing: border-box;
    cursor: crosshair;
    font-family: var(--texto);
    scroll-behavior: smooth;
}

*::-webkit-scrollbar {
    display: none;
  }

:root{
    /* Fuentes */
    --titulo: 'Six Caps', sans-serif;
    --texto: 'Work Sans', sans-serif;
    /* Colores */
    --negro: #0f0a0a;
    --blanco: #fffefb;
    --secundario: #d9dbd2;
    --terciario: #7c7d7c;
    --detalles: #919681;
    --extra: #DCFC37;
}

h1{
    font-family: var(--titulo);
}

a{
    text-decoration: none;
    color: var(--negro);
}

a:visited{
    color: var(--negro);
}

section{
    overflow: hidden;
}