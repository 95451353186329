/* NAVBAR */
.nav__container {
    position: fixed;
    width: 100%;
    z-index: 9998;
    background-color: rgba(255, 255, 255, 0.1);
    backdrop-filter: blur(10px);
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid var(--extra);
    padding: 0.5rem 5rem;
}

.nav__logo img {
    width: 150px;
}

.nav__menu {
    display: flex;
    gap: 10rem;
    list-style: none;
}

.nav__links {
    text-decoration: none;
    transition: color 0.5s;
}

.nav__menu a {
    color: var(--secundario);
    font-family: var(--texto);
}

.nav__links:hover {
    color: var(--extra);
}

.nav__btn {
    background-color: var(--extra);
    border: 1px solid var(--negro);
    border-radius: 7px;
    padding: 0.5rem;
    color: var(--negro);
    transition: color 0.3s;
}

.nav__btn:hover {
    background-color: transparent;
    color: var(--extra);
    border: 1px solid var(--extra);
}

/* NAV RESPONSIVE */

.responsiveNav_container {
    display: none;
}

.menu__responsive {
    display: none;
}

/* BODY */
.body {
        background: linear-gradient(to right, #29323c, #485563);
        min-height: 100vh;
        display: flex;
        justify-content: center;
        position: relative;
}

/* SNOW */
.snow {
    background-image: url('/public/assets/snow.gif');
    background-size: cover;
    background-position: center;
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 0;
    top: 0;
    left: 0;
}
  

/* HOME */

.home__container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100vh;
    position: relative;
}

.home__tituloContainer {
    display: flex;
    flex-direction: column;
    width: fit-content;
    position: relative;
}

.home__titulo {
    font-size: 23rem;
    font-family: var(--titulo);
    color: var(--terciario);
    height: max-content;
    overflow-x: hidden;
}

.home__texto_pre {
    font-size: 3rem;
    color: var(--blanco);
    letter-spacing: 0.05rem;
    position: absolute;
    top: 32px;
    left: 10px;
}

.home__texto_post {
    font-size: 2rem;
    color: var(--blanco);
    letter-spacing: 0.05rem;
    position: absolute;
    bottom: 30px;
    right: 0;
}

.home__container_span {
    font-size: 2rem;
    color: var(--extra);
    letter-spacing: 0.05rem;
}

/* ABOUT  */
.about__container {
    display: flex;
    width: 100%;
    height: 100vh;
    gap: 2rem;
    padding: 0 20%;
    align-items: flex-start;
    justify-content: center;
    opacity: 1;
    transition: opacity 10s;
}

.about__container.animated {
    opacity: 1;
}

.about__col {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    max-width: 40%;
}

.about__profile{
    width: 100%;
    height: auto;
    position: relative;
}

.about__profile::before{
    content: "";
    position: absolute;
    left: -1rem;
    top: 1rem;
    width: 100%;
    height: 100%;
    border: 1px solid var(--extra); 
}

.about__profile img{
    width: 100%;
    height: auto;
}

.about__paramContainer {
    width: 100%;
    display: block;
}

.about__param {
    width: 100%;
    white-space: wrap;
    color: var(--secundario);
}

.about__param_responsive {
    display: none;
}

.about__param_responsive {
    display: none;
}

/* TECS */  

.tecs__container {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.tecs__containerTitle {
    font-size: 2rem;
    color: var(--secundario);
}

.tecs__badges {
    display: flex;
    gap: 0.5rem;
    flex-wrap: wrap;
    margin-top: 0.5rem;
}

.tecs__container_responsive {
    width: 100%;
    display: none;
    flex-direction: column;
    gap: 1rem;
    justify-content: center;
    align-items: flex-start;
    text-align: left;
}


/* PROJECTS */

.projects__container {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    justify-content: space;
    align-items: center;
    width: 100%;
    height: auto;
    margin-top: 10rem;
}

.projects__itemContainer{
    display: none;
}

.projectItem{
    display: none;
}

.projects__cardsContainer {
    width: 100%;
    height: fit-content;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 2rem;
    justify-content: center;
    overflow: auto;
}

.projectCard {
    width: 20rem;
    height: 20rem;
    border: 1px solid var(--terciario);
    color: var(--terciario);
    display: flex;
    flex-wrap: wrap ;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
}

.projectCard_overlay {
    display: flex;
    gap: 0.5rem;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: absolute;
    background: rgba(15, 10, 10, 0.8);
    visibility: hidden;
    width: 100%;
    height: 100%;
    backdrop-filter: none;
}

.projectCard:hover .projectCard_overlay {
    visibility: visible;
    backdrop-filter: blur(10px);
}

.projectCard_titulo {
    font-size: 2.5rem;
    color: var(--secundario);
    text-align: center;
}

.projectCard_icono {
    font-size: 2.5rem;
    color: var(--secundario);
    text-align: center;
    cursor: pointer;
}

.projectCard_tecs {
    font-size: 1rem;
    text-align: center;
}

.projectCard_descripcion {
    font-size: 1rem;
    text-align: center;
    padding: 1rem;
    color: var(--secundario);
}

/* SETUP */

.setup__container {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.setup__img {
    width: 50rem;
    height: auto;
    border: 1px solid var(--terciario);
    box-shadow: -4px 0px 10px var(--terciario);
    margin-top: 8rem;
}

.setup__cardsContainer {
    width: 50rem;
    display: flex;
    gap: 2rem;
    flex-wrap: wrap;
    justify-content: space-between;
}

/* ITEMCARD */

.itemcard {
    width: 30%;
    height: 10rem;
    border: 1px solid var(--terciario);
    color: var(--terciario);
    display: flex;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    overflow: hidden;
}

.itemcard__overlay {
    display: flex;
    gap: 0.5rem;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: absolute;
    background: rgba(15, 10, 10, 0.8);
    visibility: hidden;
    width: 100%;
    height: 100%;
    backdrop-filter: none;
}

.itemcard:hover .itemcard__overlay {
    display: flex;
    gap: 0.5rem;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: absolute;
    background: rgba(15, 10, 10, 0.8);
    visibility: visible;
    width: 100%;
    height: 100%;
    backdrop-filter: none;
}

.itemcard__texto {
    font-size: 1.5rem;
    color: var(--secundario);
    text-align: center;
}

.itemcard__icon {
    font-size: 1.5rem;
    z-index: 9991;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    width: 24px;
    height: 24px;
}

/* SOCIAL */

.social__container {
    position: fixed;
    left: 0;
    bottom: 0;
    z-index: 9999;
    display: flex;
    gap: 2rem;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: var(--blanco);
    padding: 0rem 5rem;
}

.social__icon {
    font-size: 1.5rem;
    color: var(--blanco);
    transition: color 0.3s ease;
}

.social__icon:hover {
    font-size: 1.5rem;
    color: var(--extra);
}

.social__line {
    height: 10rem;
    width: 2px;
    background-color: var(--blanco);
    content: '';
}

.social__containerMail {
    position: fixed;
    right: 0;
    bottom: 0;
    display: flex;
    gap: 2rem;
    z-index: 9999;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: var(--blanco);
    padding: 0rem 5rem;
}

.social__containerMail-texto {
    writing-mode: vertical-lr;
    text-transform: uppercase;
    font-family: var(--texto);
    cursor: pointer;
    transition: color 0.3s ease;
}

.social__containerMail-texto:hover {
    color: var(--extra);
}

.home__social_responsive_container{
    display: none;
    width: 100%;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    padding: 0.5rem;
}

/* RESPONSIVE */
@media screen and (max-width: 1000px) {

    /* NAVBAR */
.nav__container {
    display: none;
}

/* NAVBAR RESPONSIVE */

.responsiveNav_container {
    display: flex;
    position: fixed;
    top: 0;
    right: 0;
    z-index: 9999;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    padding: 5rem;
}

.responsiveNav_icono {
    font-size: 1.5rem;
    color: var(--extra);
    cursor: pointer;

}

.responsiveNav_logo {
    width: 40px;
    background: none;
}

.menu__responsive {
    display: none;
    position: absolute;
    background: linear-gradient(to right, #29323c, #485563);;
    right: 0;
    top: 0;
    width:  100%;
    height: 100vh;
    padding: 0.5rem;
    border-radius: 8px 8px 8px 8px;
    border: 2px solid var(--extra);
}

.active{
    display: block;
}

.menu__responsive-ul {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    list-style: none;
}

.menu__responsive-ul a {
    color: var(--extra);
}
.menu__responsive-links{
    font-size: 8rem;
    font-family: var(--titulo);
    color: var(--extra);
}

.menu__responsive-hr {
    width: 100%;
    height: 1px;
    background: var(--extra);
}

.responsiveNav_icono-cerrar {
    font-size: 2rem;
    color: var(--extra);
    cursor: pointer;
    position: absolute;
    top: 2.5rem;
    right: 2.5rem;
}

.body {
flex-direction: column;
height: 100%;
width: 100%;
}

/* HOME */

.home__container {
    display: flex;
    flex-direction: column;
}

.home__titulo {
    font-size: 12rem;
}

.home__texto_pre {
    font-size: 1rem;
    color: var(--blanco);
    letter-spacing: 0.05rem;
    position: absolute;
    top: 12px;
    left: 10px;
}

.home__texto_post {
    font-size: 1rem;
    color: var(--blanco);
    letter-spacing: 0.05rem;
    position: absolute;
    bottom: 2px;
    right: 0;
}

.home__container_span {
    font-size: 1rem;
    color: var(--extra);
    letter-spacing: 0.05rem;
}

/* ABOUT  */
.about__container {
    margin-top: 10rem;
    display: flex;
    flex-direction: column;
    height: fit-content;
}

.about__container.animated {
    opacity: 1;
}

.about__paramContainer {
    display: none;
}

.about__param {
    display: none;
}

.about__param_responsive {
    display: flex;
    color: var(--secundario);
}

.about__param_responsive {
    display: block;
    font-size: 1rem;
    color: var(--secundario);
    padding-bottom: 1rem;
}

.about__profile{
    width: 100%;
    height: auto;
    position: relative;
    left: 80%;	
}

.about__profile::before{
    content: "";
    position: absolute;
    left: -1rem;
    top: 1rem;
    width: 100%;
    height: 100%;
    border: 1px solid var(--extra); 
}

.about__profile img{
    width: 100%;
    height: auto;
}

/* TECS */  

.tecs__container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    flex-direction: col;
    gap: 1rem;
}

.tecs__containerTitle {
    font-size: 1rem;
    color: var(--secundario);
}

.tecs__badges {
    display: flex;
    gap: 0.5rem;
    flex-wrap: wrap;
    margin-top: 0.5rem;
    width: 100%;
}


/* PROJECTS */

.projects__container {
    display: flex;
}

.projects__cardsContainer {
display: none;
}

.projects__itemContainer {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    justify-content: center;
    align-items: center;
    padding-top: 10rem;
}

.projectItem{
    width: 50%;
    height: auto;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    background: rgba(124, 125, 124, 0.1);
    box-shadow: 0px 0px 10px var(--negro);
    padding: 0.5rem;
}

.projectItem__img {
    width: 100%;
}

.projectItem_top {
    display: flex;
    gap: 1rem;
    justify-content: space-between;
}

.projectItem_titulo {
    font-size: 1.5rem;
    color: var(--secundario);
}

.projectItem_icono {
    font-size: 1.5rem;
    color: var(--secundario);
}

/* SETUP */

.setup__container {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
}

.setup__img {
    width: 25rem;
    height: auto;
    border: 1px solid var(--terciario);
    box-shadow: -4px 0px 10px var(--terciario);
    margin-top: 8rem;
}

.setup__cardsContainer {
    width: 25rem;
    display: flex;
    gap: 2rem;
    flex-wrap: wrap;
    justify-content: space-between;
}

/* ITEMCARD */

.itemcard {
    width: 100%;
    height: 10rem;
    border: 1px solid var(--terciario);
    color: var(--terciario);
    display: flex;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 2;
    position: relative;
    overflow: hidden;
}

.itemcard__overlay {
    display: flex;
    gap: 0.5rem;
    flex-direction: column;
    align-items: bottom;
    justify-content: center;
    position: absolute;
    bottom: 0;
    background: rgba(15, 10, 10, 0.8);
    visibility: visible;
    width: 100%;
    height: 50%;
    backdrop-filter: none;
}

.itemcard__texto {
    font-size: 1rem;
    color: var(--secundario);
    text-align: center;
}

.itemcard__icon {
    font-size: 1rem;
    z-index: 9991;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    width: 16px;
    height: 16px;
}

/* SOCIAL */
.social__container {
    display: none;
}

.social__containerMail {
    display: none;
}

.home__social_responsive_container{
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    padding: 0.5rem;
}

.social__line_responsive {
    display: flex;
    height: 2px;
    background: var(--blanco);
    width: 25%;
}

    /* final de la media query 768px */
}    

@media screen and (max-width: 425px) {

    /* NAVBAR */
.nav__container {
    display: none;
}

/* NAVBAR RESPONSIVE */

.responsiveNav_container {
    display: flex;
    position: fixed;
    top: 0;
    right: 0;
    z-index: 9999;
    width: 100%;
    justify-content: space-between;
    padding: 2.5rem;
}

.responsiveNav_icono {
    font-size: 1.5rem;
    color: var(--extra);
    cursor: pointer;

}

.responsiveNav_logo {
    width: 40px;
    background: none;
}

.menu__responsive {
    display: none;
    position: absolute;
    background: linear-gradient(to right, #29323c, #485563);;
    right: 0;
    top: 0;
    width:  100%;
    height: 100vh;
    padding: 0.5rem;
    border-radius: 8px 8px 8px 8px;
    border: 2px solid var(--extra);
}

.active{
    display: block;
}

.menu__responsive-ul {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    list-style: none;
}

.menu__responsive-ul a {
    color: var(--extra);
}
.menu__responsive-links{
    font-size: 8rem;
    font-family: var(--titulo);
    color: var(--extra);
}

.menu__responsive-hr {
    width: 100%;
    height: 1px;
    background: var(--extra);
}

.responsiveNav_icono-cerrar {
    font-size: 2rem;
    color: var(--extra);
    cursor: pointer;
    position: absolute;
    top: 2.5rem;
    right: 2.5rem;
}

/* HOME */

.home__container {
    width: 100%;
    overflow-x: hidden;
}

.home__titulo {
    font-size: 6rem;
}

.home__texto_pre {
    font-size: 1rem;
    color: var(--blanco);
    letter-spacing: 0.05rem;
    position: absolute;
    top: 0px;
    left: 0px;
}

.home__texto_post {
    font-size: 0.6rem;
    color: var(--blanco);
    letter-spacing: 0.05rem;
    position: absolute;
    bottom: 0px;
    right: 0px;
}

.home__container_span {
    font-size: 0.6rem;
    color: var(--extra);
    letter-spacing: 0.05rem;
}

/* ABOUT  */
.about__container {
    margin-top: 10rem;
    display: flex;
    flex-direction: column;
    padding: 3rem;
}

.about__container.animated {
    opacity: 1;
}

.about__paramContainer {
    display: none;
}

.about__param {
    display: none;
}

.about__param_responsive {
    display: flex;
    color: var(--secundario);
    font-size: 0.5rem;
}

.about__param_responsive {
    display: block;
    font-size: 0.5rem;
}

.about__profile{
    width: 100%;
    height: auto;
    position: relative;
    left: 80%;	
}

.about__profile::before{
    content: "";
    position: absolute;
    left: -0.5rem;
    top: 0.5rem;
    width: 100%;
    height: 100%;
    border: 1px solid var(--extra); 
}

.about__profile img{
    width: 100%;
    height: auto;
}

/* TECS */  

.tecs__container {
    display: none;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 1rem;
    justify-content: center;
    align-items: center;
}

.tecs__containerTitle {
    font-size: 0.8rem;
    color: var(--secundario);
}

.tecs__badges {
    display: none;
}

.tecs__container_responsive {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    justify-content: center;
    align-items: flex-start;
    text-align: left;
}

.tecs__containerTitle_responsive {
    font-size: 1rem;
    color: var(--secundario);
}

.tecs__badges_responsive img {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}

.tecs__container_line {
    display: block;
    width: 100%;
}

/* PROJECTS */

.projects__container {
    display: flex;
}

.projects__cardsContainer {
display: none;
}

.projects__itemContainer {
    display: flex;
    flex-direction: column;
    height: max-content;
    width: 100%;
    gap: 1rem;
    align-items: flex-start;
    justify-content: space-between;
    padding: 0 2.5rem; ;
}

.projectItem{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 1rem;
    background: rgba(124, 125, 124, 0.1);
    box-shadow: 0px 0px 10px var(--negro);
    padding: 0.5rem;
}

.projectItem__img {
    width: 100%;
}

.projectItem_top {
    display: flex;
    gap: 1rem;
    justify-content: space-between;
    width: 100%;
}

.projectItem_titulo {
    font-size: 1rem;
    color: var(--secundario);
}

.projectItem_icono {
    font-size: 1rem;
    color: var(--secundario);
}

.projectItem_tecs {
    display: none;
}

.projectItem_descripcion {
    display: none;
}

/* SETUP */

.setup__container {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
}

.setup__img {
    width: 15rem;
    height: auto;
    border: 1px solid var(--terciario);
    box-shadow: -4px 0px 10px var(--terciario);
    margin-top: 8rem;
}

.setup__cardsContainer {

    width: 100%;
    display: flex;
    gap: 2rem;
    justify-content: center;
    align-items: center;
}

/* ITEMCARD */

.itemcard {
    width: 50%;
    height: 8rem;
    border: 1px solid var(--terciario);
    color: var(--terciario);
    display: flex;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    overflow: hidden;
}

.itemcard__overlay {
    display: flex;
    gap: 0.5rem;
    flex-direction: row;
    align-items: bottom;
    justify-content: space-between;
    position: absolute;
    bottom: 0;
    background: rgba(15, 10, 10, 0.8);
    visibility: visible;
    width: 100%;
    height: min-content;
    backdrop-filter: none;
    padding: 0.5rem ;
}

.itemcard__texto {
    font-size: 1rem;
    color: var(--secundario);
    text-align: center;
    text-align: left;
}

.itemcard__icon {
    font-size: 1rem;
    z-index: 9991;
    position: relative;
    left: 50%;
    transform: translateX(-50%);
    width: 16px;
    height: 16px;
}

/* SOCIAL */
.social__container {
    position: fixed;
    left: 0;
    bottom: 0;
    z-index: 9999;
    display: none;
    gap: 2rem;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: var(--blanco);
    padding: 0rem 5rem;
}

.social__icon {
    font-size: 1rem;
    color: var(--secundario);
    transition: color 0.3s ease;
}

.social__line {
    height: 10rem;
    width: 2px;
    background-color: var(--blanco);
    content: '';
}

.social__containerMail {
    position: fixed;
    right: 0;
    bottom: 0;
    display: none;
    gap: 2rem;
    z-index: 9999;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: var(--blanco);
    padding: 0rem 5rem;
}

.social__containerMail-texto {
    writing-mode: vertical-lr;
    text-transform: uppercase;
    font-family: var(--texto);
    cursor: pointer;
    transition: color 0.3s ease;
}

.home__social_responsive_container{
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    padding: 0.5rem;
}

.social__line_responsive {
    display: flex;
    height: 2px;
    background: var(--blanco);
    width: 25%;
}

/* final de la media query 360px */
}    

